type FieldDefinition = {
    name: string;
    type: "string" | "number" | "bool";
    sortOrder?: number;
    sortDirection?: "asc" | "desc";
}

type DataSourceDefinition = {
    id: string;
    viewName: string;
    maxRecords: number;
    whereClause: string;
    loadRecents: boolean;
    distinctRows: boolean;
    dynamicLoading?: boolean;
    fields: FieldDefinition[];
}

export const local_dsOrigCodesLkp: DataSourceDefinition = {
    id: 'local_dsOrigCodesLkp',
    viewName: 'aviw_Arena_OriginatorCodesLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string" }
    ]
}

export const local_dsReceiverCodesLkp: DataSourceDefinition = {
    id: 'local_dsReceiverCodesLkp',
    viewName: 'aviw_Correspondence_ReceiverCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string" },
        { name: "IdPath", type: "string"}
    ]
}

export const local_dsReviewClasses: DataSourceDefinition = {
    id: 'local_dsReviewClasses',
    viewName: 'aviw_Arena_ReviewClasses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" }
    ]
}

export const local_dsClassificationCode: DataSourceDefinition = {
    id: 'local_dsClassificationCodes',
    viewName: 'aviw_Assets_ClassificationCodesLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string" }
    ]
}

export const local_dsTopics: DataSourceDefinition = {
    id: 'local_dsTopics',
    viewName: 'aviw_Arena_TopicsLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "NameAndDescription", type: "string" }]
}

export const local_dsProjectCodes: DataSourceDefinition = {
    id: 'local_dsProjectCodes',
    viewName: 'aviw_Assets_ProjectCodesLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "OrgUnit", type: "string" }]
}

export const local_dsDisciplines: DataSourceDefinition = {
    id: 'local_dsDisciplines',
    viewName: 'aviw_Assets_DisciplinesLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" }]
}

export const local_dsProjectPhases: DataSourceDefinition = {
    id: 'local_dsProjectPhases',
    viewName: 'atbv_Scope_ProjectPhases',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const local_dsMainAreas: DataSourceDefinition = {
    id: 'local_dsMainAreas',
    viewName: 'aviw_Assets_MainAreasLookupNT',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}


export const local_dsOrgUnitsLkp: DataSourceDefinition = {
    id: 'local_dsOrgUnitsLkp',
    viewName: 'sviw_System_OrgUnits',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "NamePath", type: "string" },
        {name: "IdPath", type: "string" },
        {name: "Parent_ID", type: "number"},
        {name: "UnitType", type: "string"},
        {name: "HasNodes", type: "bool"},
        {name: "NameAndTitle", type: "string"}]
}

export const local_dsSubProjects: DataSourceDefinition = {
    id: 'local_dsSubProjects',
    viewName: 'aviw_Scope_SubProjects',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" }]
}